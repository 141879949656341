<template>
    <div>
    <!--根据名字搜索-->
        <div style="width: 20%;display: flex">
            <el-input v-model="user_name" placeholder="请输入姓名"/>
            <el-button type="primary" @click="get_user">搜索</el-button>
            <el-button type="danger" @click="add_new_user">新增</el-button>
        </div>
        <!--人员列表-->
        <div style="min-height: 70vh">
            <el-table :data="users" style="width: 48%">
                <el-table-column prop="userName" label="姓名" width="300" />
                <el-table-column prop="roomName" label="操作室名称" width="300" />
                <el-table-column prop="a" label="操作" width="200">
                    <template #default="scope">
                        <el-button type="primary" @click="update_user(scope.row)"> 修改 </el-button>
                        <el-popconfirm
                                width="220"
                                confirm-button-text="确认"
                                cancel-button-text="取消"
                                :icon="InfoFilled"
                                icon-color="#626AEF"
                                title="是否确认删除?"
                                @confirm="del_user(scope.row)"
                        >
                            <template #reference>
                                <el-button type="danger"> 删除 </el-button>
                            </template>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--分页-->
        <div v-if="first" style="float: right;margin-top: 20px;">
            <el-pagination v-model:currentPage="pagenum"
                           :small="true"
                           background layout="prev, pager, next" :total="totals">
            </el-pagination>
        </div>
        <div v-if="!first" style="float: right;margin-top: 20px;">
            <el-pagination v-model:currentPage="pagenum1"
                           :small="true"
                           background layout="prev, pager, next" :total="totals1">
            </el-pagination>
        </div>
    <!--    新增操作室人员-->
        <div>
            <el-dialog
                    v-model="addUserDialogVisible"
                    title="新增操作室人员"
                    width="30%"
                    :before-close="handleClose"
            >
                <div style="text-align: center">
                    <el-input style="width: 60%;margin-bottom: 5%" v-model="add_new_user_name" placeholder="请输入人员名称"/>
                    <br/>
                    <el-select v-model="room_id" class="m-2" placeholder="请选择对应操作室" size="large"  style="width: 60%">
                        <el-option
                                v-for="item in rooms"
                                :key="item.sysRoomName"
                                :label="item.sysRoomName"
                                :value="item.id"
                        />
                    </el-select>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleClose">取消</el-button>
                        <el-button type="primary" @click="add_new_user_but()"> 确认新增 </el-button>
                    </span>
                </template>
            </el-dialog>
        </div>

        <!--    修改操作室人员-->
        <div>
            <el-dialog
                    v-model="updateUserDialogVisible"
                    title="修改操作室人员"
                    width="30%"
                    :before-close="handleClose"
            >
                <div style="text-align: center">
                    <el-select v-model="update_room_id" class="m-2" placeholder="请选择对应操作室" size="large"  style="width: 60%">
                        <el-option
                                v-for="item in rooms"
                                :key="item.sysRoomName"
                                :label="item.sysRoomName"
                                :value="item.id"
                        />
                    </el-select>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleClose">取消</el-button>
                        <el-button type="primary" @click="update_user_but()"> 确认修改 </el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
    name: "sys_user",
    inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
    data() {
        return {
            user_name: '',
            users: [],
            pagenum: 1,
            totals: 0,
            pagenum1: 1,
            totals1: 0,
            first: true,
            addUserDialogVisible: false,
            room_id: '',
            rooms: [],
            add_new_user_name: '',
            updateUserDialogVisible: false,
            update_room_id: '',
            update_id: ''
        }
    },
    created() {
        this.get_user()
    },
    methods: {
        get_user(){
            if (this.user_name !== ''){
                this.first = false
            }
            this.axios.get('/sysUserRoom/getSysUser', (response) => {
                this.users = response.obj.records;
                this.totals = response.obj.pages * 10;
            },{
                user_name: this.user_name,
                pageCount: 1
            })
        },
        add_new_user(){
            this.addUserDialogVisible = true
            this.axios.get('/sysRoom/getSysRoom', (response) => {
                this.rooms = response.obj
            })
        },
        handleClose(){
            this.addUserDialogVisible = false
            this.get_user()
            this.add_new_user_name = ''
            this.room_id = ''
        },
        add_new_user_but(){
            this.axios.post('/sysUserRoom/addNewUser', (response) => {
                if (response.obj){
                    this.add_new_user_name = ''
                    this.room_id = ''
                    this.get_user()
                    ElMessage({
                        message: '新增人员成功!',
                        type: 'success'
                    })
                }else {
                    ElMessage.error('新增人员失败，请联系科信部!')
                }
            },{
                user_name: this.add_new_user_name,
                room_id: this.room_id
            })
        },
        del_user(item){
            this.axios.post('/sysUserRoom/updateUser', (response) => {
                if (response.obj){
                    this.get_user()
                    ElMessage({
                        message: '删除人员成功!',
                        type: 'success'
                    })
                }else {
                    ElMessage.error('删除人员失败，请联系科信部!')
                }
            },{
                id: item.id,
                remove: 1
            })
        },
        update_user(item){
            this.updateUserDialogVisible = true
            this.update_room_id = item.roomName
            this.update_id = item.id
            this.axios.get('/sysRoom/getSysRoom', (response) => {
                this.rooms = response.obj
            })
        },
        update_user_but(){
            this.axios.post('/sysUserRoom/updateUser', (response) => {
                if (response.obj){
                    this.get_user()
                    this.updateUserDialogVisible = false
                    ElMessage({
                        message: '修改人员成功!',
                        type: 'success'
                    })
                }else {
                    ElMessage.error('修改人员失败，请联系科信部!')
                }
            },{
                id: this.update_id,
                room_id: this.update_room_id,
                remove: 0
            })
        }
    },
    watch: {
        pagenum(){
            this.axios.get('/sysUserRoom/getSysUser', (response) => {
                this.users = response.obj.records;
                this.totals = response.obj.pages * 10;
            },{
                user_name: this.user_name,
                pageCount: this.pagenum
            })
        },
        pagenum1(){
            this.axios.get('/sysUserRoom/getSysUser', (response) => {
                this.users = response.obj.records;
                this.totals = response.obj.pages * 10;
            },{
                user_name: this.user_name,
                pageCount: this.pagenum1
            })
        }
    }
}
</script>

<style>

</style>