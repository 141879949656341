<template>
    <div>
        <div>
            <Header></Header>
        </div>
        <div id="admindiv1">
            <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item style="font-size: 13px">试验室管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <div id="forms_div2">
            <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
	            <el-tab-pane label="操作室管理" name="a"><Sys_room v-if="a" /></el-tab-pane>
	            <el-tab-pane label="操作人员管理" name="e"><Sys_user v-if="e" /></el-tab-pane>
	            <el-tab-pane label="试件管理" name="c"  ><Sys_material v-if="c" /></el-tab-pane>
	            <el-tab-pane label="操作内容管理" name="b"  ><Sys_message v-if="b" /></el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import {ElMessage} from "element-plus";
import Header from "@/views/header";
import Sys_room from "@/views/sys/admin/sys_room.vue";
import {mapGetters} from "vuex";
import Sys_user from "@/views/sys/admin/sys_user.vue";
import Sys_message from "@/views/sys/admin/sys_message.vue"
import Sys_material from "@/views/sys/admin/sys_material.vue";

export default {
    name: "sys_index",
    components: {Header, Sys_room, Sys_user, Sys_message, Sys_material},
    inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
    data() {
        return {
	        activeName: 'a',
	        a: true,
	        b: false,
	        e: false,
	        c: false
        }
    },
    created() {
	    if (!this.$route.params.key){
		    this.$router.push("/manage")
	    }
    },
    methods: {
        handleClick(tab, event){
            if (tab.props.name === 'a'){
	            this.a = true
	            this.b = false
	            this.e = false
	            this.c = false
            }
            if (tab.props.name === 'b'){
	            this.b = true
	            this.a = false
	            this.e = false
	            this.c = false
            }
            if (tab.props.name === 'e'){
	            this.b = false
	            this.a = false
	            this.e = true
	            this.c = false
            }
	        if (tab.props.name === 'c'){
		        this.b = false
		        this.a = false
		        this.e = false
		        this.c = true
	        }
        },
    }
}
</script>

<style scoped>
#admindiv1{
    border-radius: 5px;
    background-color: white;
    display: flex;
    width: 95%;
    height: 40px;
    margin: 10px auto 30px;
    justify-content: space-between;
}

#forms_div2{
    border-radius: 5px;
    margin: 0 auto;
    background-color: white;
    width: 95%;
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 500px;
}
</style>
