<template>
    <div>
        <!-- 新增操作室 -->
        <div style="display: flex;width: 30%">
            <el-input v-model="room_name" placeholder="请输入操作室名称"/>
            <el-button type="primary" @click="add_new_room()">新增</el-button>
        </div>
        <!-- 操作室-->
        <div>
            <el-table :data="sys_rooms" style="width: 30%">
                <el-table-column prop="sysRoomName" label="操作室名称" width="300" />
                <el-table-column prop="a" label="操作" width="200">
                    <template #default="scope">
                        <el-button type="primary" @click="update_room(scope.row)"> 修改 </el-button>
                        <el-popconfirm
                                width="220"
                                confirm-button-text="确认"
                                cancel-button-text="取消"
                                :icon="InfoFilled"
                                icon-color="#626AEF"
                                title="是否确认删除?"
                                @confirm="del_room(scope.row)"
                        >
                            <template #reference>
                                <el-button type="danger"> 删除 </el-button>
                            </template>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 修改操作室名称弹窗-->
        <div>
            <el-dialog
                    v-model="updateDialogVisible"
                    title="修改操作室名称"
                    width="30%"
                    :before-close="handleClose"
            >
                <div style="display: flex;width: 100%">
                    <el-input v-model="update_room_name" placeholder="请输入操作室名称"/>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleClose">取消</el-button>
                        <el-button type="primary" @click="update_room_name_but()"> 确认修改 </el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {InfoFilled} from "@element-plus/icons";

export default {
    name: "dbd_leadout",
    inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
    data() {
        return {
            sys_rooms: [],
            room_name: '',
            update_id: '',
            update_room_name: '',
            updateDialogVisible: false
        }
    },
    created() {
        this.get_sys_room()
    },
    methods: {
        get_sys_room(){
            this.axios.get('/sysRoom/getSysRoom', (response) => {
                this.sys_rooms = response.obj
            })
        },
        add_new_room(){
            this.axios.post('/sysRoom/addNewRoom', (response) => {
                if (response.obj){
                    this.reload()
                    ElMessage({
                        message: '新增操作室成功!',
                        type: 'success'
                    })
                }else {
                    ElMessage.error('新增操作室失败，请联系科信部!')
                }
            },{
                room_name: this.room_name
            })
        },
        del_room(item){
            this.axios.post('/sysRoom/updateRoom', (response) => {
                if (response.obj){
                    this.reload()
                    ElMessage({
                        message: '删除操作室成功!',
                        type: 'success'
                    })
                }else {
                    ElMessage.error('删除操作室失败，请联系科信部!')
                }
            },{
                id: item.id,
                remove: '1'
            })
        },
        update_room(item){
            this.update_id = item.id
            this.update_room_name = item.sysRoomName
            this.updateDialogVisible = true
        },
        handleClose(){
            this.update_room_name = ''
            this.updateDialogVisible = false
        },
        update_room_name_but(){
            this.axios.post('/sysRoom/updateRoom', (response) => {
                if (response.obj){
                    this.reload()
                    ElMessage({
                        message: '修改操作室成功!',
                        type: 'success'
                    })
                }else {
                    this.updateDialogVisible = false
                    ElMessage.error('修改操作室失败，请联系科信部!')
                }
            },{
                id: this.update_id,
                room_name: this.update_room_name,
                remove: '0'
            })
        }
    }
}
</script>

<style>

</style>