<template>
	<div>
		<!--根据操作室搜索-->
		<div style="width: 20%;display: flex">
			<el-input v-model="room_name" placeholder="请输入操作室名称"/>
			<el-button type="primary" @click="get_message">搜索</el-button>
			<el-button type="danger" @click="add_new_message">新增</el-button>
		</div>
		<!--工作内容列表-->
		<div style="min-height: 70vh">
			<el-table :data="messages" style="width: 70%">
				<el-table-column prop="roomName" label="操作室名称" width="300"/>
				<el-table-column prop="materialName" label="试件名称" width="300"/>
				<el-table-column prop="workMessage" label="工作内容" width="300"/>
				<el-table-column prop="a" label="操作" width="200">
					<template #default="scope">
						<el-popconfirm
							width="220"
							confirm-button-text="确认"
							cancel-button-text="取消"
							:icon="InfoFilled"
							icon-color="#626AEF"
							title="是否确认删除?"
							@confirm="del_message(scope.row)"
						>
							<template #reference>
								<el-button type="danger"> 删除</el-button>
							</template>
						</el-popconfirm>

					</template>
				</el-table-column>
			</el-table>
		</div>

		<!--分页-->
		<div v-if="first" style="float: right;margin-top: 20px;">
			<el-pagination v-model:currentPage="pagenum"
			               :small="true"
			               background layout="prev, pager, next" :total="totals">
			</el-pagination>
		</div>
		<div v-if="!first" style="float: right;margin-top: 20px;">
			<el-pagination v-model:currentPage="pagenum1"
			               :small="true"
			               background layout="prev, pager, next" :total="totals1">
			</el-pagination>
		</div>
		<!--    新增操作室工作内容-->
		<div>
			<el-dialog
				v-model="addMessageDialogVisible"
				title="新增操作室工作内容"
				width="30%"
				:before-close="handleClose"
			>
				<div style="text-align: center">

					<el-select v-model="room_id" class="m-2" placeholder="请选择对应操作室" size="large" style="width: 60%" @change="getMaterial">
						<el-option
							v-for="item in rooms"
							:key="item.sysRoomName"
							:label="item.sysRoomName"
							:value="item.id"
						/>
					</el-select>
					<div style="margin-bottom: 2%">

					</div>
					<el-select v-model="material_id" class="m-2" placeholder="请选择试件名称" size="large" style="width: 60%;margin-bottom: 2%">
						<el-option
							v-for="item in materials"
							:key="item.sysMaterial"
							:label="item.sysMaterial"
							:value="item.id"
						/>
					</el-select>
					<el-input style="width: 60%;margin-bottom: 5%" v-model="add_new_message_input" placeholder="请输入工作内容"/>
				</div>
				<template #footer>
                    <span class="dialog-footer">
                        <el-button @click="handleClose">取消</el-button>
                        <el-button type="primary" @click="add_new_message_but()"> 确认新增 </el-button>
                    </span>
				</template>
			</el-dialog>
		</div>

	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "sys_message",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			room_name: '',
			messages: [],
			pagenum: 1,
			totals: 0,
			pagenum1: 1,
			totals1: 0,
			first: true,
			addMessageDialogVisible: false,
			room_id: '',
			material_id: '',
			rooms: [],
			materials: [],
			add_new_message_input: '',
		}
	},
	created() {
		this.get_message()
	},
	methods: {
		get_message() {
			if (this.room_name !== '') {
				this.first = false
			}
			this.axios.get('/sysMessage/getSysMessage', (response) => {
				this.messages = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				room_name: this.room_name,
				pageCount: 1
			})
		},
		add_new_message() {
			this.addMessageDialogVisible = true
			this.axios.get('/sysRoom/getSysRoom', (response) => {
				this.rooms = response.obj
			})


		},
		getMaterial(){
			this.axios.get('/sysMaterial/getMaterialByRoomId', (response) => {
				this.materials = response.obj
			},{
				id: this.room_id
			})
		},
		handleClose() {
			this.add_new_message_input = ''
			this.room_id = ''
			this.material_id = ''
			this.addMessageDialogVisible = false
			this.get_user()
		},
		add_new_message_but() {
			this.axios.post('/sysMessage/addNewMessage', (response) => {
				if (response.obj) {
					this.add_new_message_input = ''
					this.room_id = ''
					this.material_id = ''
					this.get_message()
					ElMessage({
						message: '新增工作内容成功!',
						type: 'success'
					})
				} else {
					ElMessage.error('新增工作内容失败，请联系科信部!')
				}
			}, {
				message: this.add_new_message_input,
				room_id: this.room_id,
				material_id: this.material_id
			})
		},
		del_message(item) {
			this.axios.post('/sysMessage/delMessage', (response) => {
				if (response.obj) {
					this.get_message()
					ElMessage({
						message: '删除工作内容成功!',
						type: 'success'
					})
				} else {
					ElMessage.error('删除工作内容失败，请联系科信部!')
				}
			}, {
				id: item.id
			})
		}
	},
	watch: {
		pagenum() {
			this.axios.get('/sysMessage/getSysMessage', (response) => {
				this.messages = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				room_name: this.room_name,
				pageCount: this.pagenum
			})
		},
		pagenum1() {
			this.axios.get('/sysMessage/getSysMessage', (response) => {
				this.messages = response.obj.records;
				this.totals = response.obj.pages * 10;
			}, {
				room_name: this.room_name,
				pageCount: this.pagenum1
			})
		}
	}
}
</script>

<style>

</style>